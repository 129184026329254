import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { createNewProduct, updateProductById, getOrders } from '../Api/products'; // Asegúrate de tener una función para obtener orders

Modal.setAppElement('#root');

const CreateProductModal = ({ isOpen, onRequestClose, onCreateOrUpdateProduct, editingProduct, token, productCount }) => {
  const orderOptions = Array.from({ length: productCount + 1 }, (_, i) => i + 1);

  const [newProductData, setNewProductData] = useState({
    title: '',
    price: '',
    description: '',
    image: null,
    type: '',
    status: false, // Cambiado a booleano para el switch
    order: 1,
  });
  const defaultOrder = productCount + 1;


  useEffect(() => {
    if (isOpen) {
      if (editingProduct) {
        setNewProductData({
          title: editingProduct.title,
          price: editingProduct.price,
          description: editingProduct.description,
          image: null,
          type: editingProduct.type,
          status: editingProduct.status,
          order: editingProduct.order,
        });
      } else {
        setNewProductData({
          title: '',
          price: '',
          description: '',
          image: null,
          type:'',
          status:'',
          order: productCount + 1, // Usa el valor por defecto para order
        });
      }
    }
  }, [isOpen, editingProduct, productCount]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files.length > 0) {
      setNewProductData((prevData) => ({ ...prevData, [name]: files[0] }));
    } else {
      setNewProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSwitchChange = () => {
    setNewProductData((prevData) => ({ ...prevData, status: !prevData.status }));
  };

  const handleTypeChange = (type) => {
    setNewProductData((prevData) => ({ ...prevData, type }));
  };

  const handleCreateOrUpdate = async () => {
    const formData = new FormData();
    formData.append('title', newProductData.title);
    formData.append('price', newProductData.price);
    formData.append('description', newProductData.description);
    formData.append('type', newProductData.type);
    formData.append('status', newProductData.status ? '1' : '0'); // Convertir a string '1' o '0'
    formData.append('order', newProductData.order);
    if (newProductData.image) {
      formData.append('image', newProductData.image);
    }

    try {
      if (editingProduct) {
        await updateProductById(token, editingProduct.id, formData);
      } else {
        await createNewProduct(formData, token);
      }
      onCreateOrUpdateProduct(); // Llama a la función de actualización
    } catch (error) {
      console.error('Error al crear/actualizar producto', error);
    }

    setNewProductData({
      title: '',
      price: '',
      description: '',
      image: null,
      type: '',
      status: false,
      order: '',
    });

    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modal Simple"
      className="Modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md p-6 max-w-md"
      overlayClassName="Overlay fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium">{editingProduct ? 'Editar Producto' : 'Crear Nuevo Producto'}</h2>
        <button
          onClick={onRequestClose}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <FaTimes />
        </button>
      </div>
      <form>
        <label className="block">Title:</label>
        <input
          type="text"
          name="title"
          value={newProductData.title}
          onChange={handleInputChange}
          className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
        />

        <label className="block">Price:</label>
        <input
          type="text"
          name="price"
          value={newProductData.price}
          onChange={handleInputChange}
          className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
        />

        <label className="block">Description:</label>
        <input
          type="text"
          name="description"
          value={newProductData.description}
          onChange={handleInputChange}
          className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
        />

        <label className="block">Image:</label>
        <input
          type="file"
          name="image"
          onChange={handleInputChange}
          className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
        />

        <label className="block">Type:</label>
        <div className="flex gap-4 mb-4">
          <button
            type="button"
            onClick={() => handleTypeChange('tarjeta')}
            className={`py-2 px-4 rounded-lg ${newProductData.type === 'tarjeta' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Tarjeta
          </button>
          <button
            type="button"
            onClick={() => handleTypeChange('accesorios')}
            className={`py-2 px-4 rounded-lg ${newProductData.type === 'accesorios' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Accesorios
          </button>
        </div>

        <label className="block">Order:</label>
        <select
          name="order"
          value={newProductData.order}
          onChange={handleInputChange}
          className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
        >
          <option value="">Seleccionar</option>
          {orderOptions.map((order) => (
            <option key={order} value={order}>
              {order}
            </option>
          ))}
        </select>

        <label className="block mb-4 flex items-center">
          <input
            type="checkbox"
            checked={newProductData.status}
            onChange={handleSwitchChange}
            className="mr-2"
          />
          Status
        </label>

        <button
          type="button"
          onClick={handleCreateOrUpdate}
          className="bg-blue-500 py-2 px-4 rounded-lg text-white hover:bg-blue-400"
        >
          {editingProduct ? 'Actualizar Producto' : 'Crear Producto'}
        </button>
      </form>
    </Modal>
  );
};

export { CreateProductModal };

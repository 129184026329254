import React, { useState, useEffect } from 'react';
import { getAllProducts, createNewProduct, updateProductById, deleteProductById } from './Api/products';
import { useSelector } from 'react-redux';
import { CreateProductModal } from './Components/CreateProductModal';
import { ConfirmationProductModal } from './Components/ConfirmationProductModal';
import CardContainer from "../../components/CardContainer";
import { FaPencilAlt, FaTrash, FaEye, FaEyeSlash } from 'react-icons/fa';

export function ProductsTable() {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const [products, setProducts] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [newProductData, setNewProductData] = useState({
    title: '',
    price: '',
    description: '',
  });
  const [productCount, setProductCount] = useState(0);


  const fetchProducts = async () => {
    try {
      const response = await getAllProducts(token);
      setProducts(response.data);
      setProductCount(response.data.length);
    } catch (error) {
      console.error('Error al obtener productos', error);
    }
  };

  const handleCreateOrUpdateProduct = async () => {
    await fetchProducts();
    setIsCreateModalOpen(false);
    setEditingProduct(null);
  };

  const handleEditClick = (product) => {
    setEditingProduct(product);
    setIsCreateModalOpen(true);
    setNewProductData({
      title: product.title,
      price: product.price,
      description: product.description,
      image: null,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      if (productToDelete) {
        await deleteProductById(token, productToDelete.id);
        fetchProducts(); // Actualizar la lista de productos después de la eliminación
      }
    } catch (error) {
      console.error('Error al eliminar el producto', error);
    } finally {
      setProductToDelete(null);
      setIsConfirmModalOpen(false);
    }
  };

  const handleCancelDelete = () => {
    setProductToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const handleDeleteProduct = (product) => {
    setProductToDelete(product);
    setIsConfirmModalOpen(true);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Ordenar productos por el campo `order` en orden ascendente
  const sortedProducts = products.slice().sort((a, b) => a.order - b.order);

  return (
    <div className="pt-8 w-full font-montserrat">
      <div className="px-8 flex justify-between items-center">
        <h2 className="font-medium text-xl truncate">
          Productos
        </h2>
        <button
          onClick={() => {
            setEditingProduct(null); // Asegúrate de que editingProduct esté vacío
            setIsCreateModalOpen(true);
          }}
          className="bg-emerald-500 py-2 px-4 rounded-lg text-white text-sm font-semibold hover:bg-emerald-400"
        >
          Crear Producto
        </button>
      </div>

      <div className="mt-4 px-6 gap-8 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
          <h2 className="font-medium text-lg mb-4 text-gray-800">Nuestros Productos</h2>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            {sortedProducts.map((product) => (
              <div
                key={product.id}
                className="relative p-2 cursor-pointer bg-white border-2 border-neutral-300 shadow-lg transition duration-300 ease-in-out"
              >
                <div className="relative mb-2">
                  <p className="text-amber-500 border-2 border-amber-500 bg-white font-semibold absolute bottom-0 right-0 p-1">
                    Bs. {product.price}
                  </p>
                  <p className="text-xs text-neutral-600 font-semibold">{product.order}</p>
                  <img
                    src={product.image} // Aquí usamos product.image como URL directa de la imagen
                    alt={product.title}
                    className="h-32 object-cover rounded-md"
                  />
                </div>
                <h3 className="font-semibold mr-2 flex">
                  <span className='text-sm text-neutral-600 font-normal'>id: {product.id} </span>
                  {product.title}
                </h3>
                <div className="flex flex-row items-center mt-2 space-x-2 text-sm">
                  <button
                    className={`flex-1 py-1 px-2 text-white flex items-center justify-center rounded-md ${
                      product.status === 1 ? 'bg-neutral-800 hover:bg-neutral-700' : 'bg-neutral-400 hover:bg-neutral-300'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // Lógica adicional si es necesario
                    }}
                  >
                    {product.status === 1 ? <FaEye /> : <FaEyeSlash />}
                  </button>
                  <button
                    className="flex-1 bg-blue-500 py-1 px-2 text-white hover:bg-blue-400 flex items-center justify-center rounded-md"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(product);
                    }}
                  >
                    <FaPencilAlt />
                  </button>
                  <button
                    className="flex-1 bg-red-500 py-1 px-2 text-white hover:bg-red-400 flex items-center justify-center rounded-md"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteProduct(product);
                    }}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </CardContainer>
      </div>

      <CreateProductModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        token={token}
        onCreateOrUpdateProduct={handleCreateOrUpdateProduct}
        editingProduct={editingProduct}
        productCount={productCount} 
      />

      <ConfirmationProductModal
        isOpen={isConfirmModalOpen}
        onRequestClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message="¿Estás seguro de que deseas eliminar este producto?"
      />
    </div>
  );
}
